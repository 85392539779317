$__uni__iconfont__data: map-merge(if(global_variable_exists('__uni__iconfont__data'), $__uni__iconfont__data, ()), (
	"Default-Zustand": "\e42a",
	"Ausblenden": "\e424",
	"Sehen": "\e112",
	"Einblenden": "\e112",
	"Meine_Klasse": "\e41a",
	"Nutzerprofil": "\e405",
	"Doppelseite": "\e486",
	"Einzelseite": "\e487",
	"Schueler-Ansicht": "\e448",
	"Lehrer-Ansicht": "\e449",
	"Show_Grid": "\e463",
	"Show_Single": "\e464",
	"Vollbildmodus": "\e42b",
	"Vollbildmodus_beenden": "\e42c",
	"Abhaken": "\e126",
	"Abschreiben": "\e145",
	"Ankreuzen": "\e11a",
	"Ausschneiden": "\e11d",
	"Beobachten": "\e113",
	"Tanzen": "\e139",
	"Bleistift_und_Lineal_verwenden": "\e136",
	"Buchstabenkarten": "\e125",
	"Dialog": "\e111",
	"Dosendiktat": "\e30c",
	"Fragediktat": "\e30b",
	"Klappdiktat": "\e30a",
	"Laufdiktat": "\e309",
	"Partnerdiktat": "\e30e",
	"Partnerdiktat_GS": "\e140",
	"Wuerfeldiktat": "\e30d",
	"Durchstreichen": "\e142",
	"Einkreisen": "\e119",
	"Lehrerexperiment": "\e305",
	"Experiment": "\e304",
	"Ableiten": "\e314",
	"Getrennt-_und_Zusammenschreibung": "\e317",
	"Gross-_und_Kleinschreibung": "\e316",
	"Rechtschreibstrategie": "\e32b",
	"Merken": "\e319",
	"Schwingen": "\e312",
	"Verlaengern": "\e313",
	"Zeichensetzung": "\e31a",
	"Zerlegen": "\e315",
	"Handeln": "\e114",
	"Hoer-Sehverstehen": "\e107",
	"Hoerverstehen": "\e106",
	"In_Schritten_loesen": "\e135",
	"Schreiben_ins_Heft": "\e10b",
	"Instrument_spielen": "\e137",
	"Klassengespraech": "\e105",
	"Klassengespraech_LmF": "\e148",
	"Kleben": "\e11e",
	"Lern-_und_Arbeitstechniken": "\e425",
	"Lesen": "\e108",
	"Lesen_mit_Partner": "\e109",
	"Lesen_mit_Partner_GS": "\e13e",
	"Malen": "\e11f",
	"Markieren": "\e117",
	"Medienkompetenz": "\e127",
	"Medienkompetenz_Grundschule": "\e493",
	"Museumsrundgang": "\e491",
	"Musikanalyse": "\e13a",
	"Musik": "\e503",
	"Nachdenken": "\e116",
	"Nachspuren": "\e124",
	"Nummerieren": "\e11b",
	"Partner-Check": "\e12c",
	"Partner-Check_GS": "\e13f",
	"Recherchieren": "\e115",
	"Reflexionsphase": "\e492",
	"Schreiben": "\e10a",
	"Sehen_LmF": "\e147",
	"Silbenboegen": "\e12f",
	"Singen": "\e138",
	"Spielen": "\e120",
	"Spielstein": "\e12d",
	"Sprechen": "\e10c",
	"Sprechen_gespiegelt": "\e14d",
	"Phonetik": "\e10d",
	"Stichworte_notieren": "\e134",
	"Szenisches_Spiel": "\e104",
	"Tabelle_anfertigen": "\e133",
	"Unterstreichen": "\e118",
	"Verbinden": "\e123",
	"Zeigen": "\e146",
	"Zuordnen": "\e11c",
	"Aufschreiben": "\e15e",
	"Wortbausteine": "\e15f",
	"Woerterkiste": "\e14e",
	"Aufgabenerweiterung": "\e32c",
	"Erweiterungsaufgabe": "\e300",
	"Vertiefungsaufgabe": "\e301",
	"Wahlaufgabe": "\e302",
	"Zusatzaufgabe": "\e303",
	"Rating_none": "\e44b",
	"Rating_half": "\e44c",
	"Rating_full": "\e44d",
	"Selbstbewertung_1": "\e31c",
	"Selbstbewertung_2": "\e31d",
	"Selbstbewertung_3": "\e31e",
	"Herz": "\e4a2",
	"erhoehte_Anforderung": "\e141",
	"Erweiterungskurs": "\e131",
	"Grundkurs": "\e130",
	"leichte_Aufgabe": "\e201",
	"mittlere_Aufgabe": "\e202",
	"schwere_Aufgabe": "\e203",
	"sehr_leichte_Aufgabe": "\e200",
	"Schwierigkeit_1_von_4b": "\e1fe",
	"Differenzierung_1": "\e207",
	"Differenzierung_2": "\e208",
	"Differenzierung_3": "\e209",
	"Differenzierung_4": "\e20a",
	"Fordernd": "\e14b",
	"ForderndPlus": "\e14c",
	"Schwierigkeit_1_von_3": "\e204",
	"Schwierigkeit_2_von_3": "\e205",
	"Schwierigkeit_3_von_3": "\e206",
	"Meisterschaft": "\e475",
	"Training": "\e474",
	"Erfolg_leer": "\e476",
	"Erfolg_niedrig": "\e43a",
	"Erfolg_mittel": "\e43b",
	"Erfolg_hoch": "\e43c",
	"Abbrechen": "\e402",
	"Abmelden": "\e41f",
	"Login": "\e472",
	"Benachrichtigungen": "\e41b",
	"Bookmark": "\e465",
	"Check": "\e407",
	"Einstellungen": "\e418",
	"Hinzufuegen": "\e438",
	"Korrekt": "\e415",
	"Home": "\e408",
	"Loeschen": "\e403",
	"Wichtig": "\e428",
	"Schliessen": "\e410",
	"Speichern": "\e404",
	"Suchen": "\e406",
	"Weniger": "\e46e",
	"WLAN": "\e488",
	"WLAN_off": "\e89",
	"Erweiterung": "\e4a4",
	"Checkmark": "\e4a1",
	"Im_Woerterbuch_suchen": "\e318",
	"Hilfe": "\e318",
	"Hilfe_ausblenden": "\e49d",
	"Hilfe_einblenden": "\e49c",
	"Information": "\e321",
	"Landeskunde": "\e121",
	"Literaturhinweis": "\e311",
	"Loesungsschluessel": "\e435",
	"Merken_Wichtig": "\e322",
	"Stolperstelle_Schild": "\e31b",
	"Stolperstelle_Warnung": "\e43d",
	"Tipp": "\e308",
	"Geschlossene_Aufgabe": "\e31f",
	"Locked": "\e31f",
	"Offene_Aufgaben": "\e320",
	"Unlocked": "\e320",
	"CO2_Neutral": "\e154",
	"Farbblinde_Geeignet": "\e152",
	"Gebaerdensprache": "\e150",
	"Landkarte": "\e14f",
	"Leichte_Sprache": "\e151",
	"Plastikfrei": "\e157",
	"Recycelt": "\e156",
	"Vegan": "\e155",
	"Filter": "\e48f",
	"Liste": "\e430",
	"Sortieren": "\e45c",
	"Sortieren_absteigend": "\e45e",
	"Sortieren_aufsteigend": "\e45d",
	"Aufgabenliste": "\e15a",
	"Audio": "\e000",
	"Audio_auf_CD": "\e001",
	"Audio_auf_USB": "\e002",
	"Audio_online": "\e003",
	"Augmented_Print": "\e429",
	"Anbeamen": "\e443",
	"Bibliothek": "\e419",
	"Bild": "\e011",
	"Bildergalerie": "\e47d",
	"Broadcast": "\e46a",
	"CD_DVD": "\e016",
	"Cloud": "\e017",
	"Computer": "\e012",
	"Dokument": "\e018",
	"Nicht_bearbeitbar": "\e143",
	"Ausdrucken": "\e444",
	"Excel-Dokument": "\e01a",
	"PDF-Dokument": "\e01b",
	"PowerPoint-Dokument": "\e46b",
	"Word-Dokument": "\e019",
	"ZIP-Datei": "\e46c",
	"Lerntagebuch": "\e306",
	"Link_auf_Webseite": "\e00f",
	"Mixed_Media": "\e010",
	"Online_Magazin": "\e470",
	"Online-Angebot": "\e132",
	"Portfolio": "\e307",
	"QR-Code": "\e144",
	"Smartphone": "\e014",
	"Smartphone_Landscape": "\e441",
	"Tablet": "\e013",
	"Tablet_Landscape": "\e442",
	"USB-Stick": "\e015",
	"Video": "\e004",
	"Video_auf_CD": "\e005",
	"Video_auf_USB": "\e006",
	"Video_online": "\e007",
	"Visualisiertes_Audio": "\e008",
	"Whiteboard": "\e423",
	"Mehr-Menu": "\e462",
	"Menu": "\e412",
	"Menu_links": "\e413",
	"Menu_rechts": "\e414",
	"Ordner": "\e495",
	"Ordner_hochladen": "\e498",
	"Ordner_erstellen": "\e496",
	"Ordner_offen": "\e49a",
	"Ordner_runterladen": "\e499",
	"Ordner_verschieben": "\e497",
	"Blick_ins_Buch": "\e46f",
	"Chevron_links": "\e455",
	"Chevron_oben": "\e457",
	"Chevron_rechts": "\e456",
	"Chevron_unten": "\e458",
	"Dragable": "\e437",
	"Dropdown_close": "\e479",
	"Dropdown_open": "\e47a",
	"Pfeil_rechts_gross": "\e478",
	"First_page": "\e459",
	"Last_page": "\e45a",
	"Pfeil_nach_links": "\e451",
	"Pfeil_nach_oben": "\e453",
	"Pfeil_nach_rechts": "\e452",
	"Pfeil_nach_unten": "\e454",
	"Reload": "\e411",
	"Reset": "\e460",
	"Verweis_Dreieck": "\e310",
	"Verweis_Pfeil": "\e30f",
	"Wechsel": "\e45f",
	"Weiter": "\e420",
	"Zurueck": "\e40d",
	"Pfeil_nach_oben_links": "\e15c",
	"Pfeil_nach_oben_rechts": "\e15d",
	"Undo": "\e4a3",
	"Headphones": "\e445",
	"Sprachaufnahme_aktivieren": "\e432",
	"Mikrofon_deaktivieren": "\e433",
	"Pause": "\e43e",
	"Play": "\e43f",
	"Stop": "\e440",
	"Ton_aus": "\e450",
	"Ton_laut": "\e44e",
	"Ton_leise": "\e44f",
	"Untertitel": "\e12b",
	"App": "\e00c",
	"Arbeitsheft": "\e01d",
	"Arbeitsheft_thematisch": "\e48c",
	"BigBook": "\e490",
	"Buchreihe": "\e49b",
	"Diagnose_und_Foerdern": "\e48e",
	"eBook": "\e00a",
	"eBook_plus": "\e00b",
	"Handreichung": "\e01f",
	"Interaktive_uebungen": "\e009",
	"Kopiervorlage": "\e020",
	"editierbare_Kopiervorlage": "\e48b",
	"Lektuere": "\e01e",
	"Paket": "\e494",
	"Schuelerbuch": "\e01c",
	"Themenband": "\e48d",
	"Unterrichtsmanager": "\e00d",
	"Vokabeltrainer-App": "\e00e",
	"Nachschlagen": "\e12e",
	"Lexikon": "\e15b",
	"Einzelarbeit": "\e100",
	"Einzelarbeit_GS": "\e13b",
	"Partnerarbeit": "\e101",
	"Partnerarbeit_LmF": "\e149",
	"Partnerarbeit_GS": "\e13c",
	"Gruppenarbeit": "\e102",
	"Gruppenarbeit_LmF": "\e14a",
	"Gruppenarbeit_GS": "\e13d",
	"Kooperative_Lernform": "\e103",
	"Sprachmittlung_EN-DE": "\e10e",
	"Sprachmittlung_IT-DE": "\e10f",
	"Sprachmittlung_RU-DE": "\e110",
	"Sprachmittlung_ES-DE": "\e158",
	"Sprachmittlung_FR-DE": "\e159",
	"Auswahl_zoomen": "\e469",
	"Highlight": "\e467",
	"Verdecken": "\e466",
	"Cloud-Download": "\e439",
	"Dokument_download": "\e401",
	"Download": "\e400",
	"Editieren": "\e434",
	"Handschrifteingabe": "\e4a0",
	"Kalender": "\e41c",
	"Kopieren": "\e48a",
	"Lesehilfe": "\e461",
	"CAS": "\e328",
	"GTR": "\e327",
	"TK": "\e325",
	"TR": "\e32a",
	"WTR": "\e329",
	"DGS": "\e326",
	"Neue_Notiz": "\e431",
	"Pointer": "\e45b",
	"Radiergummi": "\e12a",
	"Satzzaehlung": "\e473",
	"Foto": "\e416",
	"Statistik": "\e44a",
	"Stundenplaner": "\e436",
	"Taschenrechner": "\e323",
	"keine_technischen_Hilfsmittel": "\e324",
	"Tastatur": "\e46d",
	"Tastatur_ausblenden": "\e49f",
	"Tastatur_einblenden": "\e49e",
	"Bold": "\e42d",
	"Italic": "\e42f",
	"Textwerkzeug": "\e468",
	"Underline": "\e42e",
	"Textkorrektur": "\e477",
	"Tools": "\e122",
	"Uhr": "\e41e",
	"externer_Link": "\e422",
	"Link": "\e421",
	"Vergroessern": "\e426",
	"Verkleinern": "\e427",
	"Schriftgroesse_skalieren": "\e153",
	"Instagram": "\e47e",
	"Twitter": "\e47f",
	"Facebook": "\e480",
	"Google_Plus": "\e481",
	"Youtube": "\e482",
	"LinkedIn": "\e483",
	"Xing": "\e484",
	"WhatsApp": "\e485",
	"Schwierigkeit_1_von_4a": "\e20b",
	"Schwierigkeit_2_von_4": "\e20c",
	"Schwierigkeit_3_von_4": "\e20d",
	"Schwierigkeit_4_von_4": "\e20e",
	"Dokument_digital": "\e500",
	"Puzzle": "\e501",
	"Anhang": "\e502",
	"Zahnraeder": "\e504",
	"Programmierer": "\e506",
	"Redakteur": "\e507",
	"Dokument_Ablauf_50": "\e508",
	"Dokument_Ablauf_100": "\e509",
	"Medien": "\e50a",
	"Lehrergespraech": "\e505",
	"Autoren_Herausgeber": "\e47c",
	"Merkzettel": "\e471",
	"Produktkranz": "\e47b",
	"Warenkorb": "\e40e",
	"Brief": "\e417",
	"Markierung": "\e446",
	"Share": "\e447",
	"Telefon": "\e40f",
	"Versenden": "\e41d"
));

// =============================================================================
// Prerequisits
// =============================================================================
//
// This template uses a $__uni__iconfont__data variable injected by the workflow
// Use ./fonts/ to store the fonts or configure your own font-path
// with the variable $uni-font-base-path


// =============================================================================
// Variables
// =============================================================================

// Directory where the font files are stored
$uni-font-base-path: './fonts/' !default;

// Whether to write css classes or not
$create-uni-icon-classes: true !default;

// Should the @font-face tag get created?
$create-uni-font-face: true !default;

// Wether to use size in pixels or to use size in rem
$uni-icon-sizes-use-rem: false !default;

// List of crystal sizes for cv-icons iconfont in px or rem)
$uni-icon-sizes: (
    s: if($uni-icon-sizes-use-rem, 1.25rem, 20px),
    m: if($uni-icon-sizes-use-rem, 1.875rem, 30px),
    l: if($uni-icon-sizes-use-rem, 2.5rem, 40px),
    xl: if($uni-icon-sizes-use-rem, 3.4375rem, 55px),
) !default;


// List of font styles
$uni-icon-styles: (
    flat: "CV UNI Icons flat",
    outline: "CV UNI Icons outline"
) !default;


// =============================================================================
// Functions
// =============================================================================

// Helper function to get the correct icon
@function uni-iconfont-item($name) {
    @if (false == map-has-key($__uni__iconfont__data, $name)) {
        @warn 'Undefined Iconfont Glyph!';
        @return '';
    }
    @return map-get($__uni__iconfont__data, $name);
}


// =============================================================================
// Mixins
// =============================================================================

// Setup base icon styles
@mixin uni-icon-base-style() {
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    vertical-align: middle;
    speak: none;
    text-transform: none;
    line-height: 1;
    font-size: 1em;

    /* Better Font Rendering on MacOSX */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Mixing to include an icon
// Examples:
// .my_icon{ @include uni-icon(Audio, m, flat, $useAfter: true) }
@mixin uni-icon($name, $size, $style, $useAfter: false) {
    $font-size: false;

    @if ($size) {
        @if type-of($size)==number and unitless($size)==false {
            $font-size: $size;
        } @else if map-has-key($uni-icon-sizes, $size) {
            $font-size: map-get($uni-icon-sizes, $size);
        } @else {
            @error "'#{$size}' is not a valid icon size. Please use a valid number with a css unit or choose from the predefined sizes: 's, m, l, xl'.";
        }
    }

    @if ($style) {
        @if not map-has-key($uni-icon-styles, $style) {
            @error "#{$style} is not an icon style.";
        }
    } @else {
        @error "Please set a $style parameter";
    }
    
    @if ($useAfter) {
        &::after {
            @include uni-icon-base-style();
            content: uni-iconfont-item($name);
            font-family: #{map-get($uni-icon-styles, $style)};

            @if $size {
                font-size: $font-size;
            }
        }
    } @else {
        &::before {
            @include uni-icon-base-style();
            content: uni-iconfont-item($name);
            font-family: #{map-get($uni-icon-styles, $style)};
            
            @if $size {
                font-size: $font-size;
            }
        }
    }
}

// Shortcut mixins to use es specific icon style
@mixin flat-uni-icon($name, $size) {
    @include uni-icon($name, $size, 'flat')
}

@mixin outline-uni-icon($name, $size) {
    @include uni-icon($name, $size, 'outline')
}


// =============================================================================
// Fontface-Rules
// =============================================================================

// Creates the font face tag if the variable is set to true (default)
@if $create-uni-font-face == true {
    @font-face {
        font-family: "CV UNI Icons outline";
        src: url('#{$uni-font-base-path}cv_uni_icon_linie.eot'); /* IE9 Compat Modes */
        src: url('#{$uni-font-base-path}cv_uni_icon_linie.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('#{$uni-font-base-path}cv_uni_icon_linie.woff2') format('woff2'), /* Pretty modern browsers */
        url('#{$uni-font-base-path}cv_uni_icon_linie.woff') format('woff'), /* Older modern browsers */
        url('#{$uni-font-base-path}cv_uni_icon_linie.svg') format('svg'); /* Legacy iOS */
        font-display: block;
    }

    @font-face {
        font-family: "CV UNI Icons flat";
        src: url('#{$uni-font-base-path}cv_uni_icon_flaeche.eot'); /* IE9 Compat modes */
        src: url('#{$uni-font-base-path}cv_uni_icon_flaeche.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('#{$uni-font-base-path}cv_uni_icon_flaeche.woff2') format('woff2'), /* Pretty modern browsers */
        url('#{$uni-font-base-path}cv_uni_icon_flaeche.woff') format('woff'), /* Older modern browsers */
        url('#{$uni-font-base-path}cv_uni_icon_flaeche.svg') format('svg'); /* Legacy iOS */
        font-display: block;
    }
}


// =============================================================================
// Css classes
// =============================================================================

// Create icon classes for each glyph
@if $create-uni-icon-classes == true {
    .cvds-icon {
        &:before {
            @include uni-icon-base-style();
        }
    }

    @each $key, $value in $uni-icon-sizes {
        .cvds-icon--size-#{$key}:before {
            font-size: var(--uni-icon-size-#{$key}, #{$value});
        }
    }

    @each $key, $value in $uni-icon-styles {
        .cvds-icon--style-#{$key}:before {
            font-family: "#{$value}";
        }
    }

    @each $key, $value in $__uni__iconfont__data {
        .cvds-icon--#{$key}:before {
            content: "#{$value}";
        }
    }
}
