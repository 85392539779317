// breakpoints
$breakpoints: (
  medium: 640px,
  large: 1280px,
);

@function get-breakpoint($name) {
  @if not map-has-key($breakpoints, $name) {
    @error '#{$name} is not a valid breakpoint.';
  }
  @return map-get($breakpoints, $name);
}

@mixin breakpointMin($name) {
  @media screen and (min-width: get-breakpoint($name)) {
    @content;
  }
}

@mixin breakpointMax($name) {
  @media screen and (max-width: get-breakpoint($name)) {
    @content;
  }
}
