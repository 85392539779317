@use '@angular/material' as mat;
@use './assets/styles/responsive' as responsive;
@use './assets/styles/variables' as variables;
// @import '@cornelsen/cv-fonts/scss/cv-dida-sans/fonts.scss';
@import '@angular/cdk/overlay-prebuilt.css';

@include mat.core();

// setup for uni icons
$uni-font-base-path: '@cornelsen/cv-uni-icons/build/fonts/';
@import '@cornelsen/cv-uni-icons/build/icons.scss';

body,
html {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: 'CV Dida Sans Flex UI', sans-serif;
  font-size: 1rem;
  line-height: 1.125rem;
  color: variables.$foreground-primary;
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3.4375rem;
}

p {
  font-size: 1.125rem;
  line-height: 1.75rem;

  @include responsive.breakpointMin(medium) {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  @include responsive.breakpointMin(large) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: variables.$background-background;
}

router-outlet + * {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
}

div::-webkit-scrollbar {
  width: 7px;
  height:10px;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
border-radius: 15px;
}

div::-webkit-scrollbar-thumb {
background-color: darkgrey;
outline: 1px solid slategrey;
border-radius: 15px;
}
