@use '@angular/material' as mat;

$color-primary: (
  50: #ffffff,
  100: #fcfcfc,
  200: #fbfbfb,
  300: #f9f9f9,
  400: #f7f7f7,
  500: #f6f6f6,
  600: #f5f5f5,
  700: #f3f3f3,
  800: #f2f2f2,
  900: #efefef,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$color-secondary: (
  50: #fafafa,
  100: #f4f4f4,
  200: #ececec,
  300: #e4e4e4,
  400: #dfdfdf,
  500: #d9d9d9,
  600: #d5d5d5,
  700: #cfcfcf,
  800: #cacaca,
  900: #c0c0c0,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$color-accent: (
  50: #000000,
  100: #2e3132,
  200: #333333,
  300: #4c4c4c,
  400: #666666,
  500: #7f7f7f,
  600: #999999,
  700: #b2b2b2,
  800: #cccccc,
  900: #eff1f3,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$color-black: (
  50: rgb(0, 0, 0),
  100: #434343,
  200: #656565,
  300: #848484,
  400: #969494,
  500: #ababab,
  600: #bcbcbc,
  700: #dedede,
  800: #e4e4e4,
  900: #ffffff,
  A100: #000000,
  A200: #5e5d5d,
  A400: #b4b4b4,
  A700: #ffffff,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #000000,
    500: #080808,
    600: #000000,
    700: #5c5c5c,
    800: #2c2c2c,
    900: #050505,
    A100: #ffffff,
    A200: #ffffff,
    A400: #000000,
    A700: #040404,
  ),
);

@function color($color, $shade) {
  @return map-get($color, $shade);
}

$primary: mat.define-palette($color-primary);
$secondary: mat.define-palette($color-secondary);
$accent: mat.define-palette($color-accent);
$black: mat.define-palette($color-black);

$dark-theme: mat.define-dark-theme($black, $primary);
$light-theme: mat.define-light-theme($primary, $secondary, $accent);

$foreground-primary: #263238;
$foreground-secondary: #fff;
$background-background: #eff1f3;
$background-emphasized: #2e3132;
$background-menu: #444749;
$background-menu-hover: #2e3132;
$background-menu-selected: #191c1e;
$background-card: #fbfcfe;
$boder-card: #eff1f3;
$boder-card-hover: #e0e0e0c2;
$close-button-background: #444749ab;
$close-button-background-hover: #444749;
$close-button-text: #e0e0e0c2;
$scrollbar-hover-color: #6a6a6a;
$scrollbar-thumb-color: #c5c7c9;
$border-color: #eff1f3;
$border-color-hover: #e0e0e0c2;
$chip: color($color-accent, 900);
$chip-color: color($color-accent, 300);
$side-background-color: color($color-accent, 100);
$base-height-large: 3rem;
$base-height-medium: 2.75rem;
$base-height-small: 2.75rem;

$avatar-background: #f2f4f5;

$border-state-focus: #263238;
$border-state-inverted-focus: #ffffff;
$button-disabled: #444749;
$button-background-disabled: #2e3132;

// primary
$button-background-primary: #2e3132;
$button-background-primary-hover: #444749;
$button-background-primary-active: #191c1e;
$button-forground-primary: #ffffff;

// secondary
$button-background-secondary: #ffffff;
$button-background-secondary-hover: #eff1f3;
$button-background-secondary-active: #e1e2e4;
$button-forground-secondary: #2e3132;
$button-border-secondary: #2e3132;

$button-foreground-free: #2e3132;
$button-background-free-interaction: #c5c7c9;
$button-hover-shadow: 0px 38px 15px rgba(0, 0, 0, 0.01),
  0px 22px 13px rgba(0, 0, 0, 0.05), 0px 10px 10px rgba(0, 0, 0, 0.09),
  0px 2px 5px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);

$button-forground-outline: #191c1e;
$button-background-outline: transparent;
$button-background-outline-interaction: #c5c7c9;
$button-border-color-outline: #191c1e;
$button-border-width-outline: 1px;

$message-button-background-interaction: #e1e2e4;

$select-foreground--dark: #fbfcfe;
$select-foreground-disabled--dark: #717171;
$select-border--dark: #5c5f61;
$select-border-hovered--dark: #9b9b9b;
$select-border-selected--dark: #a9a9a9;
$select-background--dark: #2e3132;
$select-background-hovered--dark: #444749;
$select-background-selected--dark: #8e8e8e;
$select-menu-background--dark: #444749;
$option-foreground--dark: #fbfcfe;
$option-background-hovered--dark: #5c5f61;
$option-background-pressed--dark: #868686;
$option-background-selected--dark: #c5c7c9;
$option-foreground-selected--dark: #191c1e;
$option-background-disabled--dark: #383838;
$option-foreground-disabled--dark: #717171;

$select-foreground--light: #191c1e;
$select-foreground-disabled--light: #c5c7c9;
$select-border--light: #5c5f61;
$select-border-hovered--light: #9b9b9b;
$select-border-selected--light: #a9a9a9;
$select-background--light: #fbfcfe;
$select-background-hovered--light: #eff1f3;
$select-background-selected--light: #c5c7c9;
$select-menu-background--light: #ffffff;
$option-foreground--light: #191c1e;
$option-background-hovered--light: #c5c7c9;
$option-background-pressed--light: #c5c7c9;
$option-background-selected--light: #eff1f3;
$option-foreground-selected--light: #191c1e;
$option-background-disabled--light: #ffffff;
$option-foreground-disabled--light: #c5c7c9;

$foreground-disclaimer: #5c5f61;

$textfield-background: #ffffff;
$textfield-foreground: #191c1e;
$textfield-placeholder: rgba(25, 28, 30, 0.26);
$textfield-border: #191c1e;
$textfield-error: #eb7f7a;

$select-error--light: #c62828;

$dialog-shadow: 0px 156px 62px rgba(24, 26, 27, 0.01),
  0px 88px 53px rgba(24, 26, 27, 0.05), 0px 39px 39px rgba(24, 26, 27, 0.09),
  0px 10px 21px rgba(24, 26, 27, 0.1), 0px 0px 0px rgba(24, 26, 27, 0.1);
$dialog-background: #ffffff;
